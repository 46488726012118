<template>
  <div class="grid">
    <CrudTable
      ref="packages"
      :entities="entities"
      :meta="metaTable"
      v-model:selection="selectedItems"
      @tableAction="onTableAction"
      @itemAction="onItemAction"
      @cell-edit-complete="onCellEditComplete"
      @paging="onPaging"
      @filter="onFilter"
      @sort="onSort"
    />
    <!--
    <div class="col-12 md:col-12">
      <DataTable ref="dt" :value="entities" v-model:selection="selectedEntity" dataKey="id" editMode="cell"
          @cell-edit-complete="onCellEditComplete"
          :showGridlines=true
          :paginator="true" :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} packages" responsiveLayout="scroll">
        <template #header>
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">{{$t('package.title_list')}}</h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="code" :header="$t('package.arrival_date')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.arrival_date')}}</span>
            {{this.formatDate(slotProps.data.arrivalDate)}}
          </template>
        </Column>
        <Column field="package_code" :header="$t('package.package_code')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.package_code')}}</span>
            {{slotProps.data.packageCode}}
          </template>
        </Column>
        <Column field="shipment_code" :header="$t('package.shipment_code')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.shipment_codes')}}</span>
            {{slotProps.data.shipmentCodes}}
          </template>
        </Column>
        <Column field="weight" :header="$t('package.weight')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.weight')}}</span>
            {{slotProps.data.weightLbs}} {{$t('unit.lbs')}} / {{tokg(slotProps.data.weightLbs)}} {{$t('unit.kg')}}
          </template>
        </Column>
        <Column field="actualWeight" :header="$t('package.actual_weight')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" mode="decimal" :suffix=" $t('unit.kg')">

              </InputNumber>
          </template>
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.actual_weight')}}</span>
            {{tolbs(slotProps.data.actualWeightKg)}} {{$t('unit.lbs')}} / {{slotProps.data.actualWeightKg}} {{$t('unit.kg')}}
          </template>
        </Column>
        <Column field="storeStatus" :header="$t('package.store_status')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.store_status')}}</span>
            {{storeStatus(slotProps.data.storeStatus)}}
          </template>
        </Column>
        <Column headerStyle="min-width:10rem;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openReceiptEdit(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteEntity(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>
          -->
  </div>
  <Dialog v-model:visible="showPackageImages" class="images">
    <template #header>
      <h3>{{ $t("package.images") }}</h3>
    </template>
    <Galleria
      v-model:visible="showPackageImages"
      :value="images"
      :responsiveOptions="responsiveOptions"
      :numVisible="5"
      :circular="true"
      containerStyle="max-width: 640px"
      :showItemNavigators="true"
      :showThumbnails="false"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.imageSrc"
          :alt="slotProps.item.alt"
          style="width: 100%; display: block"
        />
      </template>

      <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.thumbnailSrc"
          :alt="slotProps.item.alt"
          style="display: block"
        />
      </template>
    </Galleria>
    <!--
    <template #footer>
      <Button :label="$t('button.save')" icon="pi pi-save" @click="onSaveMessage" autofocus />
    </template>
    -->
  </Dialog>
</template>
<script>
import { h } from "vue";
import CrudTable from "@/pages/common/CrudTable";
import PackageService from "@/service/PackageService";
import ReceiptService from "@/service/ReceiptService";
import AuthService from "@/service/AuthService";
import ImageService from "@/service/ImageService";
import adjustment from "@/utils/adjustment";
import formatter from "@/utils/formatter";
import { converter } from "@/utils/common";
import { Consts } from "@/utils/consts";
import preview from "@/mixins/preview";
import filter from "@/mixins/filter";
import { writeFile, utils } from "xlsx";
import { serializeOrders, serializeFilters } from "@/utils/params";
// const PackageCodeTemplate = () =>
//   <div>
//     {/* <Button label={data.packageCode} link class="p-button-text underline" click="openReceipt(data.packageCode)"/> */}
//     <span> hello </span>
//   </div>
export default {
  components: { CrudTable },
  mixins: [preview, filter],
  data() {
    let t = this.$t;
    const format_process_status = function (status) {
      return formatter.formatPackageProcessStatus(t, status);
    };
    const metaTable = {
      entityName: "package",
      expander: false,
      searchable: true,
      rowEditor: true,
      lazy: true,
      tableClass: "p-datatable-sm",
      editMode: "cell",
      sortMode: "multiple",
      multiSortMeta: [{ field: "createdDate", order: -1 }],
      selectionMode: "multiple",
      actionsHeaderStyle: "width:5%; min-width:5rem;",
      paginator: {
        enable: true,
        rows: Consts.DEFAULT_PAGE_ROWS,
        rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
        currentPageReportTemplate:
          "Showing {first} to {last} of {totalRecords} packages",
      },
      filter: {
        filterDisplay: "menu",
        globalFilterFields: ["packageCode", "shipmentCode", "awbCode"],
      },
      columns: [
        {
          name: "createdDate",
          headerStyle: "width:8%; min-width:8rem;",
          filter: true,
          dataType: "date",
          formatter: formatter.formatDate,
        },
        {
          name: "packageCode",
          headerStyle: "width:8%; min-width:10rem;",
          filter: true,
          editor: {
            enable: false,
          },
          //component: PackageCodeTemplate
          //component: this.createPackageCode,
        },
        {
          name: "shipmentCode",
          headerStyle: "width:8%; min-width:8rem;",
          filter: true,
          link: this.openShipment,
        },
        {
          name: "receiptCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          link: this.openReceipt,
        },
        {
          name: "referenceCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "shipmentReferenceCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "receiptReferenceCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "awbCode",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
        },
        {
          name: "weightPartnerLbs",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: converter.formatLbsWeight,
        },
        {
          name: "weightActualKg",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: converter.formatKgWeight,
        },
        {
          name: "processStatus",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          filterOptions: [1, 2, 3, 4, 5].map(status => ({value: status, label: format_process_status(status)})),
          dataType: "numeric",
          formatter: format_process_status,
        },
        {
          name: "appearance",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: this.formatAppearance,
        },
        {
          name: "complaint",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true
        },
        {
          name: "storeNote",
          headerStyle: "width:20%; min-width:10rem;",
          filter: true,
          editor: {
            enable: false,
          },
        },
        {
          name: "description",
          headerStyle: "width:30%; min-width:30rem;",
          filter: true,
        },
      ],
      tableActions: [
        {
          name: "filterclear",
          icon: "pi pi-filter-slash",
          style: "p-button-outlined mr-2",
        },
        {
          name: "print",
          icon: "pi pi-print",
          style: "mr-2",
        },
        {
          name: "export",
          icon: "pi pi-download",
          style: "mr-2",
          condition: "receipts?.length > 0",
        },
      ],
      itemActions: [
        // {
        //   name: "edit",
        //   icon: "pi pi-pencil",
        //   style: "p-button-rounded mr-2",
        //   condition: "mode != 'edit'"
        // },
        // {
        //   name: "delete",
        //   icon: "pi pi-trash",
        //   style: "p-button-rounded p-button-warning mr-2",
        //   condition: "mode != 'edit'"
        // },
      ],
      metaSubTable: {
        entityName: "packageItem",
        dataField: "packageItems",
        paginator: {
          enable: false,
        },
        columns: [
          {
            name: "name",
            headerStyle: "width:30%; min-width:10rem;",
            filter: true,
            editor: {
              enable: false,
            },
          },
          {
            name: "quantity",
            headerStyle: "width:20%; min-width:10rem;",
            filter: true,
            editor: {
              enable: false,
            },
          },
          {
            name: "description",
            headerStyle: "width:50%; min-width:10rem;",
            filter: true,
            editor: {
              enable: false,
            },
          },
        ],
      },
    };
    return {
      entities: [],
      filters: {},
      filterOptions: {
        page: 0,
        size: Consts.DEFAULT_PAGE_ROWS,
      },
      selectedEntity: null,
      selectedItems: [],
      totalRecords: 0,
      currentUser: null,
      metaTable,
      showPackageImages: false,
      images: [],
    };
  },
  created() {
    this.service = PackageService;
  },
  mounted() {
    this.currentUser = AuthService.currentUser;
    this.filterOptions.sorts = serializeOrders({
      multiSortMeta: this.metaTable.multiSortMeta,
    });
    this._loadEntities();
  },
  methods: {
    onPaging(pageEvent) {
      this.filterOptions.page = pageEvent.page;
      this.filterOptions.size = pageEvent.rows;
      this._loadEntities();
    },
    async _loadEntities() {
      const response = await PackageService.getAll(this.filterOptions);
      if (Array.isArray(response.content)) {
        response.content.forEach((item) => adjustment.adjustPackage(item));
        this.entities = response.content;
        this.entities.forEach((pkg) => {
          if (pkg.itemDtos) {
            pkg.description = pkg.itemDtos
              ?.map((item) => item.quantity + " " + item.name)
              .join(", ");
          }
        });
        this.metaTable.paginator.totalRecords = response.totalElements;
      } else {
        this.entities = [];
        this.metaTable.paginator.totalRecords = 0;
      }
    },
    onFilter(filterEvent) {
      const filter = serializeFilters(filterEvent);
      if (this.filterOptions.filter != filter) {
        this.filterOptions.filter = filter;
        this._loadEntities();
      }
      // for(var field in event.filters) {
      //   if (field == 'global') continue;
      //   let notNullConstraints = event.filters[field].constraints.filter(item=> item.value != null);
      //   if (notNullConstraints.length > 0) {
      //     options.filters[field] = {
      //       constraints: notNullConstraints,
      //       operator:event.filters[field].operator
      //     };
      //   }
      // }
      // this.filters = options.filters;
    },
    onSort(sortEvent) {
      this.filterOptions.sorts = serializeOrders(sortEvent);
      this._loadEntities();
    },
    createPackageCode({ data }) {
      //console.log(data.packageCode, column);
      //const Button = resolveComponent("Button");
      let parts = data.packageCode?.split("-");
      const routeData = this.$router.resolve({
        name: "users_receipt_view",
        params: { id: data.receiptId },
      });
      if (Array.isArray(parts) && parts.length >= 2) {
        // return h("div", [h(Button, {
        //   click: () => {},
        //   label: parts[0],
        //   link:true
        // }), h("div", ["-", parts[1]])]);
        return h("div", { class: "flex" }, [
          h(
            "a",
            {
              href: routeData.href,
            },
            parts[0]
          ),
          h("div", ["-", parts[1]]),
        ]);
      } else {
        return h("div");
      }

      //return  (<Button :label="slotProps.data[column.name]" link class="p-button-text underline" @click="column.link(slotProps.data,column)"/>);
    },
    formatAppearance(status) {
      return status;
      // switch (status) {
      //   case 0:
      //     return "";
      //   case 1:
      //     return this.$t('package.abnormal');
      // }
    },
    formatDate(jsDate) {
      return formatter.formatDate(jsDate);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    tolbs(kg) {
      return converter.tolbs(kg);
    },
    storeStatus(status) {
      return isNaN(status) ? "" : this.$t("package.store_status_" + status);
    },
    onTableAction(sAction) {
      if (sAction == "print") {
        const ids = btoa(this.selectedItems.map((item) => item.id).join(","));
        if (ids) {
          const routeData = this.$router.resolve({
            name: "print_preview",
            params: { resource: "package", id: ids },
          });
          window.open(routeData.href);
        }
      } else if (sAction == "export") {
        this._exportExcel();
      }
    },
    openShipment(data) {
      this.$router.push({
        name: "agent_shipment_detail",
        params: { id: data.shipmentId },
      });
    },
    openReceipt(data) {
      this.$router.push({
        name: "users_receipt_view",
        params: { id: data.receiptId },
      });
    },
    onItemAction(sAction, entity, index) {
      // if(sAction == 'edit') {
      //   this.$router.push({ name: 'agent_package_edit', params: {receiptId: entity.id} });
      // }
      if (sAction == "print") {
        ReceiptService.get(entity.id).then((data) => {
          if (Array.isArray(data.content) && data.content.length == 1) {
            const receiptId = data.content[0].id;
            //this.$router.push({ name: 'print_preview_shippinglabel', params: {resource: "receipt", id: receiptId, packageId: entity.id} });
            const routeData = this.$router.resolve({
              name: "print_preview",
              params: {
                resource: "receipt",
                id: receiptId,
                packageId: entity.id,
              },
            });
            //this.openPreview(routeData.href);
            window.open(routeData.href);
          }
        });
      } else if (sAction == "delete") {
        console.log(entity);
      } else if (sAction == "qrcode") {
        console.log(entity);
      } else if (sAction == "images") {
        this.showPackageImages = true;
        this.loadImages(entity);
      }
      console.log(index);
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
        case "actualWeight":
          if (this.isPositiveInteger(newValue)) {
            data[field] = newValue;
            this.service.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;

        default:
          if (newValue.trim().length > 0) {
            data[field] = newValue;
            this.service.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;
      }
    },
    loadImages(receiptPkg) {
      if (receiptPkg) {
        ImageService.loadImages(receiptPkg).then((response) => {
          //const baseUrl = Utils.getImageBase();
          if (Array.isArray(response)) {
            this.images = response;
          }
        });
      }
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    async _exportExcel() {
      //Load all packages
      const queryOptions = {...this.filterOptions, page: 0, size: 10240};
      const response = await PackageService.getAll(queryOptions);
      if (!Array.isArray(response.content)) {
        return;
      }
      const mapReceipts = {};
      const allPackages = response.content;
      allPackages.forEach((pkg) => {
        adjustment.adjustPackage(pkg);
        if (pkg.receiptId && pkg.receipt) {
          mapReceipts[pkg.receiptId] = pkg.receipt;
        }
      });
      //Create the sheet
      const sheet = this._createPackageSheet(mapReceipts, allPackages);
      var wb = utils.book_new(); // make Workbook of Excel
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, sheet, "Packages"); // sheetAName is name of Worksheet
      // export Excel file
      const currentDate = new Date();
      const fileName = "packages-" + currentDate.getTime() + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createPackageSheet(mapReceipts, packages) {
      const hdrDate = this.$t("package.createdDate");
      const hdrCode = this.$t("package.packageCode");
      const hdrShipmentCode = this.$t("package.shipmentCode");
      const hdrWeightLbs = this.$t("package.weightLbs");
      const hdrWeightKg = this.$t("package.weightKg");
      const hdrProcessStatus = this.$t("package.processStatus");
      const hdrStoreStatus = this.$t("package.storeStatus");
      const hdrStoreNote = this.$t("package.storeNote");
      const hdrSender = this.$t("receipt.senderName");
      const hdrSenderPhone = this.$t("receipt.senderPhone");
      const hdrSenderState = this.$t("receipt.senderState");
      // const hdrSenderCity = this.$t("receipt.senderCity");
      // const hdrSenderCounty = this.$t("receipt.senderCounty");
      const hdrSenderAddress = this.$t("receipt.senderAddress");
      const hdrRecipient = this.$t("receipt.recipientName");
      const hdrRecipientPhone = this.$t("receipt.recipientPhone");
      const hdrProvince = this.$t("address.province");
      const hdrDistrict = this.$t("address.district");
      // const hdrWard = this.$t("address.ward");
      const hdrAddress = this.$t("receipt.recipientAddress");
      const hdrDescription = this.$t("package.description");
      const sheetData = packages.map((entity, ind) => {
        const receipt = mapReceipts[entity.receiptId] || {};
        let senderAddress = [];
        let recipientAddress = [];
        if (receipt.recipientAddress) {
          recipientAddress.push(receipt.recipientAddress);
        }
        if (receipt.recipientWard) {
          recipientAddress.push(receipt.recipientWard);
        }
        if (receipt.recipientDistrict) {
          recipientAddress.push(receipt.recipientDistrict);
        }
        if (receipt.recipientProvince) {
          recipientAddress.push(receipt.recipientProvince);
        }
        if (receipt.senderAddress) {
          senderAddress.push(receipt.senderAddress);
        }
        if (receipt.senderCity) {
          senderAddress.push(receipt.senderCity);
        }
        if (receipt.senderCounty) {
          senderAddress.push(receipt.senderCounty);
        }
        let date;
        try {
          date = new Date(entity.createdDate);
        } catch (e) {
          console.error(e);
        }
        return {
          ["Index"]: ind + 1,
          [hdrDate]: date,
          [hdrCode]: entity.packageCode,
          [hdrShipmentCode]: entity.shipmentCode,
          [hdrWeightLbs]: entity.weightLbs,
          //[hdrWeightKg]: converter.tokg(entity.weightLbs),
          [hdrWeightKg]: entity.weightKg,
          [hdrProcessStatus]: formatter.formatPackageProcessStatus(
            this.$t,
            entity.processStatus
          ),
          [hdrStoreStatus]: this.formatStoreStatus(entity),
          [hdrStoreNote]: entity.storeNote,
          [hdrSender]: receipt.senderName,
          [hdrSenderPhone]: receipt.senderPhone,
          [hdrSenderState]: receipt.senderState,
          // [hdrSenderCity]: receipt.senderCity,
          // [hdrSenderCounty]: receipt.senderCounty,
          // [hdrSenderAddress]: receipt.senderAddress,
          [hdrSenderAddress]: senderAddress.join(","),
          [hdrRecipient]: receipt.recipientName,
          [hdrRecipientPhone]: receipt.recipientPhone,
          [hdrAddress]: recipientAddress.join(","),
          [hdrProvince]: receipt.recipientProvince,
          [hdrDistrict]: receipt.recipientDistrict,
          // [hdrWard]: receipt.recipientWard,
          // [hdrAddress]: receipt.recipientAddress,
          [hdrDescription]: receipt.description,
        };
      });
      var sheet = utils.json_to_sheet(sheetData, {
        origin: "A1",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 10 }, //date
        { wch: 12 }, //packageCode
        { wch: 10 }, //Shipment code
        { wch: 6 }, //Weightlbs
        { wch: 6 }, //kg
        { wch: 12 }, //Status
        { wch: 12 }, //StoreStatus
        { wch: 20 }, //Store Note
        { wch: 20 }, //Sender name
        { wch: 12 }, //Sender phone
        { wch: 20 }, //Sender State
        // { wch: 20 }, //Sender City
        // { wch: 20 }, //Sender County
        { wch: 30 }, //Sender Address
        { wch: 18 }, //Recipient's name
        { wch: 12 }, //Recipient's phone
        { wch: 30 }, //Address
        { wch: 20 }, //Province
        { wch: 20 }, //District
        // { wch: 20 }, //Ward

        { wch: 30 }, //Description
      ];
      return sheet;
    },
  },
};
</script>
<style>
.images {
  min-width: 600px;
}
</style>
